import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useClientEnv } from '~/lib/env';

import * as gtag from './gtag.client';

const routesToTrack = ['/oauth2', '/auth'];

export function GoogleTagManagerScript() {
  const location = useLocation();

  const { GOOGLE_TAG_ID } = useClientEnv();

  useEffect(() => {
    if (
      GOOGLE_TAG_ID?.length &&
      routesToTrack.some((route) => location.pathname.startsWith(route))
    ) {
      gtag.pageview(location.pathname, GOOGLE_TAG_ID);
    }
  }, [GOOGLE_TAG_ID, location.pathname]);

  if (GOOGLE_TAG_ID === undefined) {
    return null;
  }

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_ID}`}
      />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          `
        }}
      />
    </>
  );
}
