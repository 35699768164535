/**
 * A script that prevents the theme and scale from
 * flashing when the page is first loaded.
 */
export function FlashPrevention() {
  function preventColorSchemeFlash() {
    const classList = document.documentElement.classList;
    const style = document.documentElement.style;

    const dark = window.matchMedia('(prefers-color-scheme: dark)');
    const fine = window.matchMedia('(any-pointer: fine)');

    function update() {
      const colorScheme = localStorage.getItem(`colorScheme`) ?? 'SYSTEM';

      if (
        colorScheme === 'DARK' ||
        (colorScheme === 'SYSTEM' && dark.matches)
      ) {
        classList.remove('convergo--light');
        classList.add('convergo--dark');
        style.colorScheme = 'dark';
      } else {
        classList.add('convergo--light');
        classList.remove('convergo--dark');
        style.colorScheme = 'light';
      }

      if (!fine.matches) {
        classList.remove('convergo--medium');
        classList.add('convergo--large');
      } else {
        classList.add('convergo--medium');
        classList.remove('convergo--large');
      }
    }

    update();

    dark.addEventListener('change', update);
    fine.addEventListener('change', update);
  }

  return (
    <script
      async
      suppressHydrationWarning
      dangerouslySetInnerHTML={{
        __html: `(${preventColorSchemeFlash})()`
      }}
    />
  );
}
