import { Theme } from '@diallink-corp/convergo-types';

/**
 * This is the theme object that is used to style the application.
 * Even though Convergo has its own default theme that it exports,
 * its currently not compatible with Remix's way of importing CSS.
 * That's why we build a mock theme here that just applies the
 * necessary CSS classes to the ConvergoProvider. All styles are
 * imported through the root.tsx links in the head.
 */
export const theme: Theme = {
  global: {
    convergo: ''
  },
  light: {
    'convergo--light': ''
  },
  dark: {
    'convergo--dark': ''
  },
  medium: {
    'convergo--medium': ''
  },
  large: {
    'convergo--large': ''
  }
};
